<template>
  <div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    <Dialog
      v-model:visible="showDispoMod"
      modal
      header="Ajouter une disponibilité"
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <select name="lieu" id="lieu" v-model="lieu">
        <option value="">
          Veuillez choisir le lieu de votre disponibilité
        </option>
        <template v-for="l in lieuxDispo" :key="l.id">
          <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
        </template>
      </select>
      <br />
      <br />
      <template v-if="loadDispoBtn == false">
        <button @click="setDispo()" class="btn btn-primary">VALIDER</button>
      </template>
      <template v-else>
        <button @click="setDispo()" class="btn btn-primary" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
      </template>
      
    </Dialog>

    <Dialog
      v-model:visible="showResaMod"
      modal
      @update:visible="handleCloseResa"
      :header="headerResa"
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
    
      <template v-if="eleve.choixDispo == 'null'">
        <h4>Veuillez choisir vos moniteurs et lieux de rendez-vous pour réserver une heure de conduite</h4>
      </template>

      <template v-else>
        <div class="stepper">
          <div class="firstStep active" id="stepOne">
            1. Choix du Mono
          </div>
          <div class="link">
            ---------------->
          </div>
          <div class="secondStep inactive" id="stepTwo">
            2. Lieu de début
          </div>
          <div class="link">
            ---------------->
          </div>
          <div class="thirdStep inactive" id="stepThree">
            3. Lieu de fin
          </div>
              
        </div>
        
        <div class="moniteur" v-if="step == 1">
          <h3>Choix du moniteur</h3>
          <div class="inputMono">
            <template v-for="m in moniteurs" :key="m.id">
              <template v-if="m.choixEleve == true">
                <label class="container"
                  >
                  <input
                    type="radio"
                    name="moniteur"
                    :value="m.idMono"
                    v-model="monoResa"
                  />
                  <span class="checkmark"></span>
                  {{ m.fullname }}

                </label>
              </template>
            </template>
          </div>
        </div>

        <div class="lieuDeb" v-if="step == 2">
          <h3>Début du cours</h3>
          <template v-if="monoResa == ''"
            ><p>Veuillez choisir le moniteur</p></template
          >
          <template v-else>
            <div class="inputMono">
              <template v-for="l in lieuxDeb" :key="l.id">
                <template v-if="l.choixEleve == true">
                  <label class="container"
                    >{{ l.nom }}
                    <input
                      type="radio"
                      name="lieudeb"
                      :value="l.id"
                      v-model="lieuDeb"
                    />
                    <span class="checkmark"></span>
                  </label>
                </template>

              </template>
            </div>
          </template>
        </div>
        <br />
        <div class="lieuFin" v-if="step == 3">
          <h3>Fin du cours</h3>
          <template v-if="monoResa == ''"
            ><p>Veuillez choisir le moniteur</p></template
          >
          <template v-else-if="lieuxDeb.length == 0"
            >Veuillez choisir le lieu de début du cours</template
          >
          <template v-else>
            <div class="inputMono">
              <template v-for="l in gpLieuxFin" :key="l">
                <template v-if="l == 1">
                    <label class="container"
                    >{{ this.$store.state.elevePlanningInfo.agence.nom }} Impérativement
                    <input
                        type="radio"
                        name="lieufin"
                        :value="998"
                        v-model="LieuFin"
                    />
                    <span class="checkmark"></span>
                    </label>
                </template>
                <template v-else-if="l == 2">
                    <template v-for="lf in lieuxFin" :key="lf.id">
                      <template v-if="l == lf.groupe">
                        <label class="container"
                          >{{ lf.nom }} Impérativement - Le cours se termineras {{ lf.time }} min avant
                          <input
                              type="radio"
                              name="lieufin"
                              :value="lf.id"
                              v-model="LieuFin"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </template>
                    </template>

                </template>
                <template v-else-if="l >= 3">

                    <template v-if="l != 100">
                        <template v-for="l in lieuxDeb" :key="l.id">
                            <template v-if="l.id == lieuDeb">
                                <label class="container"
                                >   {{ this.$store.state.elevePlanningInfo.agence.nom }} ou {{l.nom}} selon les besoins de l'agence
                                <input
                                    type="radio"
                                    name="lieufin"
                                    :value="1000"
                                    v-model="LieuFin"
                                />
                                <span class="checkmark"></span>
                                </label>
                            </template>
                        </template>

                    </template>

                </template>

              </template>
            </div>
          </template>
        </div>

        <br />
        <br />
        <button @click="goBackInStep()" class="btn btn-primary" v-if="step != 1">RETOUR</button>
        <button @click="choixMonoResa()" class="btn btn-primary stepBtn" v-if="step == 1">ETAPE SUIVANTE</button>
        <button @click="getLieuFin()" class="btn btn-primary stepBtn" v-if="step == 2">ETAPE SUIVANTE</button>
        <template v-if="loadResaBtn === false">
          <button @click="setResa()" class="btn btn-primary stepBtn" v-if="step == 3">VALIDER</button>
        </template>
        <template v-else>
          <button @click="setResa()" class="btn btn-primary stepBtn" v-if="step == 3" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
        </template>
        
      </template>


    </Dialog>

    <Dialog
      v-model:visible="popupMotif"
      modal
      header="Motif d'annulation du cours"
      :style="{ width: '50rem' }"
    >
      <select name="typeMotf" id="typeMotf" v-model="motif.leTypeMotif">
        <option value="null">Saisir le type de motif</option>
        <template v-for="t in typeMotif" :key="t.id">
          <option :value="t">{{ t.label }}</option>
        </template>
      </select>
      <br>
      <input
        type="text"
        placeholder="Saisir un commentaire"
        id="motifText"
        v-model="motif.com"
      />
      <br /><br />
      <template v-if="motif.leTypeMotif != null">
        <template v-if="motif.com != ''">
          <template v-if="loadMotifBtn == false">
            <button class="btn btn-primary btn-sm btnValMotif" @click="setMotif">
              VALIDER
            </button>
          </template>
          <template v-else>
            <button @click="setMotif()" class="btn btn-primary btn-sm btnValMotif" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
          </template>

        </template>
      </template>
    </Dialog>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

export default {
  props: ["eleve", "getEleveInfo"],

  components: {
    FullCalendar,
  },

  data() {
    return {
      loadResaBtn : false,
      loadMotifBtn : false,
      loadDispoBtn : false,
      headerResa : "",
      step : 1,
      gpLieuxFin : [],
      motif: {
        leTypeMotif: null,
        com: "",
      },
      typeMotif: [],
      popupMotif: false,
      firstCours: false,
      boiteResa: "",
      lieuxDeb: [],
      lieuDeb: "",
      lieuxFin: [],
      LieuFin: "",
      monoResa: "",
      moniteurs: [],
      lieuxDispo: [],
      showDispoMod: false,
      showResaMod: false,
      lieu: "",
      selectEvent: "",
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        nowIndicator: true,
        eventResizableFromStart: true,
        firstDay: 1,
        allDaySlot: false,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:00:00",
        slotDuration: "00:30:00",
        height: "auto",
        locale: "fr",
        selectable: true,
        timeZone: "local",
        eventClick: this.deleteEvent,
        eventDidMount: this.eventhover,
        customButtons: {
          hideSunday: {
            text: "Dimanche",
            click: (e) => this.hideSundayMethod(e),
            background: "red",
          },
          prev: {
            // this overrides the prev button
            text: "PREV",
            click: () => {
              this.$store.commit("setDispoResaBtn", false);
              this.todayBtnActive = false;
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();
              let date = moment(calendarApi.getDate().toString()).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              
              if(this.dayWeekPlanning == false){
                this.getEleveInfoChange(date, false);
              }else{
                this.getEleveInfoChange(date, true);
              }

              let resultDispo = this.calendarOptions.events.filter(
                (e) => e.type != "dispo"
              );
              this.calendarOptions.events = resultDispo;

                if (this.dayWeekPlanning == false) {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {
                          if (
                            calendarApi.getDate().getDay() == 0
                          ) {
                            let start = moment(e.start).subtract(1, "week");
                            let end = moment(e.end).subtract(1, "week");
                            e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                            e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                          }else{
                            if(this.nextBtnPress){
                              let start = moment(e.start).subtract(1, "week");
                              let end = moment(e.end).subtract(1, "week");
                              e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                              e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                              this.nextBtnPress = false;
                            }
                          }
                      }
                    });
                  }
                } else {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {
                        let start = moment(e.start).subtract(1, "week");
                        let end = moment(e.end).subtract(1, "week");
                        e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                        e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                      }
                    });
                  }
                }

            },
          },
          next: {
            // this overrides the next button, essayer de récupérer la semaine afficher suite auc lique du bouton next
            text: "NEXT",
            click: () => {
              this.$store.commit("setDispoResaBtn", false);
              this.todayBtnActive = false;
              this.nextBtnPress = true;
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();
              let date = moment(calendarApi.getDate().toString()).format(
                "YYYY-MM-DDTHH:mm:ss"
              );

              if(this.dayWeekPlanning == false){
                this.getEleveInfoChange(date, false);
              }else{
                this.getEleveInfoChange(date, true);
              }

              let resultDispo = this.calendarOptions.events.filter(
                (e) => e.type != "dispo"
              );
              this.calendarOptions.events = resultDispo;

                if (this.dayWeekPlanning == false) {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {

                          if (
                            moment(e.start).add(1, 'day').format("YYYY-MM-DD") == moment(calendarApi.getDate().toString()).format("YYYY-MM-DD")
                          ) {
                            let start = moment(e.start).add(1, "week");
                            let end = moment(e.end).add(1, "week");
                            e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                            e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                          }
                        
                      }
                    });
                  }
                } else {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {
                        let start = moment(e.start).add(1, "week");
                        let end = moment(e.end).add(1, "week");
                        e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                        e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                      }
                    });
                  }
                }

            },
          },

          today: {
            // test
            text: "Aujourd'hui",
            click: () => {
              this.$store.commit("setDispoResaBtn", false);
              this.todayBtnActive = false;
              this.nextBtnPress = false;
              let calendarApi = this.$refs.fullCalendar.getApi();
              if (this.calendarOptions.events != null) {
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.getEleveInfoChange(moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.getEleveInfoChange(date, true);
                }
              }

              calendarApi.today();
            },
          },

          timeGridDay: {
            // this overrides the next button
            text: "Jour",
            click: () => {
              this.$store.commit("setDispoResaBtn", false);
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView("timeGridDay");
              calendarApi.today();
              this.dayWeekPlanning = false;
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              //this.$emit("getMoniteursChange", moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
              if(this.calendarOptions.events != null){
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.getEleveInfoChange(moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.getEleveInfoChange(date, true);
                }
              }
            },
          },

          timeGridWeek: {
            // this overrides the next button
            text: "Semaine",
            click: () => {
              this.$store.commit("setDispoResaBtn", false);
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView("timeGridWeek");
              calendarApi.today();
              this.dayWeekPlanning = true;
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              if(this.calendarOptions.events != null){
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.getEleveInfoChange(moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.getEleveInfoChange(date, true);
                }
              }
            },
          },
        },
        headerToolbar: {
          start: "prev,next",
          center: "title",
          end: "timeGridWeek,timeGridDay",
        },
        events: [],
        select: this.select,
        editable: true,
        selectAllow: this.selectAllow,
        eventChange: this.putDispoEleve,
      },
      dayWeekPlanning: true,
      todayBtnActive : true,
      nextBtnPress : false,
    };
  },

  mounted() {

    this.getLieuxEleve();
    this.getMoniteurs();
    this.putOnPlanningEleve();
  },

  methods: {

    getEleveInfoChange(date, planningForm){
      window.api
        .post("/eleve/conduite/" + this.$store.state.user.id + "/changeplan", 
        {
          date : date,
          planningForm: planningForm
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$store.commit("setElevePlanningInfo", response.data.eleve[0]);
          if (this.eleve.choixDispo == "null") {
            this.$store.commit("setShowMonoElevePage", true);
          }
          
          this.putOnPlanningEleve();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    putOnPlanningEleve(){

      if (this.calendarOptions.events.length != 0) {
        let filterEvent = this.calendarOptions.events.filter(
          (e) => e.type != "resa"
        );
        this.calendarOptions.events = filterEvent;
      }

      this.$store.state.elevePlanningInfo.dispos.forEach((d) => {
      this.calendarOptions.events.push({
          id: d.id,
          title: "Dispo - " + d.lieu.nom,
          start: moment(d.start.date).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(d.end.date).format("YYYY-MM-DDTHH:mm:ss"),
          color: "blue",
          type: "dispo",
        });
      });

      this.$store.state.elevePlanningInfo.apointments.forEach((r) => {

        if(r.lieuFin.nom == "Cer impérativement"){
          let fin = r.lieuFin.nom.substring(3);
          r.lieuFin.nom = this.$store.state.elevePlanningInfo.agence.nom + fin;
        }else if(r.lieuFin.nom == "CER ou lieu début selon les besoins de l'agence"){
          let fin = r.lieuFin.nom.substring(18);
          r.lieuFin.nom = this.$store.state.elevePlanningInfo.agence.nom + " ou " + r.lieuDeb.nom + " " + fin;
        }

        let statAac = "";

        if(r.statAac != "null"){
          statAac = r.statAac;
        }

        if (r.motif == "null") {
          if (r.done) {
            this.calendarOptions.events.push({
              id: r.id,
              title:
                statAac + " " +
                r.moniteur.fullname +
                "  deb : " +
                r.lieuDeb.nom +
                "/ fin :" +
                r.lieuFin.nom,
              start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
              color: "teal",
              type: "resa",
              done: true,
              editable: false,
              motif: false,
              resourceId: r.moniteur.idMoni,
              display : "background"
            });
          } else {
            this.calendarOptions.events.push({
              id: r.id,
              title:
                statAac + " " +
                r.moniteur.fullname +
                "  deb : " +
                r.lieuDeb.nom +
                "/ fin :" +
                r.lieuFin.nom,
              start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
              color: "green",
              type: "resa",
              done: false,
              editable: false,
              motif: false,
              resourceId: r.moniteur.idMoni,
              display : "background"
            });
          }
        } else {
          if (r.typeMotif == "Eleve Absent") {
            let hDiff = this.diff_hours(
              new Date(r.start.date),
              new Date(r.cancelRdv.date)
            );

            if (hDiff <= 48) {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  statAac + " " +
                  r.moniteur.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom +
                  "motif : Eleve Absent - " + r.motif,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "red",
                type: "resa",
                done: false,
                editable: false,
                motif: true,
                resourceId: r.moniteur.idMoni,
                display : "background"
              });
            }
          } else if (r.typeMotif == "Mono Absent") {
            this.calendarOptions.events.push({
              id: r.id,
              title:
                statAac + " " +
                r.moniteur.fullname +
                "  deb : " +
                r.lieuDeb.nom +
                "/ fin :" +
                r.lieuFin.nom +
                "motif : Mono Absent - " + r.motif,
              start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
              color: "maroon",
              type: "resa",
              done: false,
              editable: false,
              motif: true,
              resourceId: r.moniteur.idMoni,
              display : "background"
            });
          } else if (r.typeMotif == "Incident") {
            this.calendarOptions.events.push({
              id: r.id,
              title:
                statAac + " " +
                r.moniteur.fullname +
                "  deb : " +
                r.lieuDeb.nom +
                "/ fin :" +
                r.lieuFin.nom +
                "motif : Incident - " + r.motif,
              start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
              color: "yellow",
              type: "resa",
              done: false,
              textColor: "black",
              editable: false,
              motif: true,
              resourceId: r.moniteur.idMoni,
              display : "background"
            });
          }
        }
      });
    },

    eventhover(info) {
      info.el.title = info.event.title;
    },

    putDispoEleve(arg) {
      if (arg.event["_def"].title.substring(0,5) == "Dispo" && this.$store.state.dispoOrResa == false) {

        window.api.put("/disponibilites/" + arg.event._def.publicId, {
          start: arg.event._instance.range.start,
          end: arg.event._instance.range.end,
          eleve: "api/utilisateurs/" + this.$store.state.user.id,

        }, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(() => {

        }).catch(error => {
          console.log(error)
        })
      }
    },

    goBackInStep(){
      if(this.step != 1){
        this.step = this.step - 1;

        if(this.step == 1){
          document.getElementById("stepTwo").classList.remove("active");
          document.getElementById("stepOne").classList.remove("inactive");
          document.getElementById("stepTwo").classList.add("inactive");
          document.getElementById("stepOne").classList.add("active");
        }else if(this.step == 2){
          document.getElementById("stepThree").classList.remove("active");
          document.getElementById("stepTwo").classList.remove("inactive");
          document.getElementById("stepThree").classList.add("inactive");
          document.getElementById("stepTwo").classList.add("active");
        }
      }
    },  

    handleCloseResa(){
      this.gpLieuxFin = [];
      this.lieuxFin = [];
    },

    setDisplayResaDispo(show){
        if(show){
            this.calendarOptions.events.forEach((event) => {
                if (event.type === "dispo") {
                    event.display = "background";
                }
            });

            this.calendarOptions.events.forEach((event) => {
                if (event.type === "resa") {
                    event.display = "";
                }
            });

        }else{
            this.calendarOptions.events.forEach((event) => {
                if (event.type === "dispo") {
                    event.display = "";
                }
            });

            this.calendarOptions.events.forEach((event) => {
                if (event.type === "resa") {
                    event.display = "background";
                }
            });
        }
    },

    setResa() {
      this.loadResaBtn = true;
      if (this.monoResa == "" || this.LieuFin == "" || this.lieuDeb == "") {
        alert(
          "Veuillez saisir un moniteur, lieu de début et un lieu de fin pour votre cours"
        );
        this.loadResaBtn = false;
      } else {
        window.api
          .post(
            "/check/limit/hour/" + this.$store.state.user.id,
            {
              date: moment(this.selectEvent.startStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              start: moment(this.selectEvent.startStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              end: moment(this.selectEvent.endStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((r) => {
            if (r.data.limit) {
              window.api
                .post(
                  "/set/apointment/" +
                    this.$store.state.user.id +
                    "/" +
                    this.monoResa +
                    "/" +
                    this.lieuDeb +
                    "/" +
                    this.LieuFin,
                  {
                    start: moment(this.selectEvent.startStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    end: moment(this.selectEvent.endStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then((response) => {

                  if(response.data.fin == "Cer impérativement"){
                    let fin = response.data.fin.substring(3);
                    response.data.fin = this.$store.state.elevePlanningInfo.agence.nom + fin;
                  }else if(response.data.fin == "CER ou lieu début selon les besoins de l'agence"){
                    let fin = response.data.fin.substring(18);
                    response.data.fin = this.$store.state.elevePlanningInfo.agence.nom + " ou " + response.data.deb + " " + fin;
                  }

                  if (response.data.message == "Solde insufisant") {
                    alert("Le solde de l'élève selectioné est insufisant");
                    this.loadResaBtn = false;
                  } else {
                    this.loadResaBtn = false;
                    this.calendarOptions.events.push({
                      id: response.data.apointment,
                      title:
                        response.data.moniteur +
                        "  deb : " +
                        response.data.deb +
                        "/ fin :" +
                        response.data.fin,
                      start: moment(this.selectEvent.startStr).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      ),
                      end: moment(this.selectEvent.endStr).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      ),
                      color: "green",
                      type: "resa",
                      done: false,
                      editable: false,
                      motif: false,
                      resourceId: this.monoResa,
                    });
                  }
                  this.$emit("getEleveInfo");
                  this.showResaMod = false;
                  this.lieuxDeb = [];
                  this.lieuxFin = null;
                  this.lieuFinChoix = null;

                  window.api
                    .post(
                      "/sendMail",
                      {
                        type: "rdv ok",
                        id: response.data.apointment,
                        email: response.data.eleveMail,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => {})
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            }else{
              alert("Vous êtes à la limite d'heure par semaine autorisé par votre agence, Si vous voulez réserver plus d'heure cette semaine passer en formation accélérée.");
              this.loadResaBtn = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getLieuFin() {
      if (this.lieuDeb != null) {
        if (this.firstCours) {
          

          let lDeb = this.lieuxDeb.find(l => l.id == this.lieuDeb);

          this.lieuxFin = this.firstCoursFin;
          let findGp1 = false;
          let findGp2 = false;
          let findGp3 = false;

          this.lieuxFin.forEach(l => {
            if(lDeb.groupe == 3){
              if(l.groupe == 1){
                if(!findGp1){
                  findGp1 = true;
                  this.gpLieuxFin.push(1);
                }
              }

              if(l.groupe == 2){
                if(!findGp2){
                  findGp2 = true;
                  this.gpLieuxFin.push(2);
                }
              }

              if(l.groupe == 3){
                if(!findGp3){
                  findGp3 = true;
                  this.gpLieuxFin.push(3);
                }
              }
            }else{
              if(l.groupe == 1){
                if(!findGp1){
                  findGp1 = true;
                  this.gpLieuxFin.push(1);
                }
              }

              if(l.groupe == 2){
                if(!findGp2){
                  findGp2 = true;
                  this.gpLieuxFin.push(2);
                }
              }
            }

            


          })
          document.getElementById("stepTwo").classList.remove("active");
          document.getElementById("stepThree").classList.remove("inactive");
          document.getElementById("stepTwo").classList.add("inactive");
          document.getElementById("stepThree").classList.add("active");
          this.step = 3;
        } else {
          window.api
            .post("get/lieux/" + this.monoResa + "/resa/" + this.lieuDeb, 
            {
              start: moment(this.selectEvent.startStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              end: moment(this.selectEvent.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.lieuxFin = response.data.fin;

              let findGp1 = false;
              let findGp2 = false;
              let findGp3 = false;
              this.lieuxFin.forEach(l => {
                if(l.groupe == 1){
                  if(!findGp1){
                    findGp1 = true;
                    this.gpLieuxFin.push(1);
                  }
                }

                if(l.groupe == 2){
                  if(!findGp2){
                    findGp2 = true;
                    this.gpLieuxFin.push(2);
                  }
                }

                if(l.groupe == 3){
                  if(!findGp3){
                    findGp3 = true;
                    this.gpLieuxFin.push(3);
                  }
                }

              })

              document.getElementById("stepTwo").classList.remove("active");
              document.getElementById("stepThree").classList.remove("inactive");
              document.getElementById("stepTwo").classList.add("inactive");
              document.getElementById("stepThree").classList.add("active");
              this.step = 3;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    choixMonoResa() {
      window.api
        .post(
          "/get/lieux/" + this.monoResa + "/resa",
          {
            start: moment(this.selectEvent.startStr).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            end: moment(this.selectEvent.endStr).format("YYYY-MM-DDTHH:mm:ss"),
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((r) => {
          
          r.data.deb.forEach(l => {
            this.$store.state.elevePlanningInfo.choixDispo[0].choix.forEach(c => {
              if(c.id == this.monoResa){
                if(c.lieux != undefined){
                  c.lieux.forEach(li => {
                    if(l.id == li){
                      l.choixEleve = true;
                    }
                  })
                }
              }
            })
          })

          this.lieuxDeb = r.data.deb;
          this.firstCours = r.data.first;
          this.firstCoursFin = r.data.fin;
          this.boiteResa = r.data.boite;
          document.getElementById("stepOne").classList.remove("active");
          document.getElementById("stepTwo").classList.remove("inactive");
          document.getElementById("stepOne").classList.add("inactive");
          document.getElementById("stepTwo").classList.add("active");
          this.step = 2;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getMoniteurs() {
      window.api
        .get("/moniteurs/agence/planning/" + this.$store.state.user.agence.id + "/null", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.moniteurs = response.data.moniteurs;


          if(this.$store.state.elevePlanningInfo.choixDispo != "null"){
              this.moniteurs.forEach(m => {
                this.$store.state.elevePlanningInfo.choixDispo[0].choix.forEach(c => {
                  if(c.id == m.idMono){
                    m.choixEleve = true;
                  }
                })
              })
            }

        })
        .catch((error) => {
          console.log(error);
        });
    },

    setDispo() {
      this.loadDispoBtn = true;
      if (this.lieu == "") {
        alert("Veuillez saisir un lieu pour votre disponibilité");
        this.loadDispoBtn = false;
      } else {
        window.api
          .post(
            "/disponibilites",
            {
              start: moment(this.selectEvent.startStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              end: moment(this.selectEvent.endStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
              lieu: "api/lieus/" + this.lieu,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.loadDispoBtn = false;
            
            let lieuFind = this.lieuxDispo.find((l) => l.id == this.lieu);
            this.calendarOptions.events.push({
              id: response.data.id,
              title: "Dispo - " + lieuFind.nom,
              start: moment(this.selectEvent.startStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              end: moment(this.selectEvent.endStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              color: "blue",
              type: "dispo",
            });
            this.showDispoMod = false;
            this.lieu = "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getLieuxEleve() {
      window.api
        .get("/eleve/lieux/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          response.data.lieux.forEach(l => {
              let findL = null;
              findL = this.lieuxDispo.find(e => e.id == l.id)
              if(findL == undefined){
                this.lieuxDispo.push(l);
              }
            })
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },

    deleteEvent(e) {
        this.selectEvent = e;
      if (e["event"]["_def"]["extendedProps"]["type"] == "dispo") {
        window.api
          .delete("/disponibilites/" + e.event["_def"].publicId, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            let events = [];
            this.calendarOptions.events.forEach((i) => {
              if (i.id != e["event"]["_def"]["publicId"]) {
                events.push(i);
              }
            });
            this.calendarOptions.events = events;
            this.$emit("getEleveInfo");
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        e["event"]["_def"]["extendedProps"]["type"] == "resa" &&
        e["event"]["_def"]["extendedProps"]["motif"] == false &&
        e["event"]["_def"]["extendedProps"]["done"] == false
      ) {
        window.api
          .get("/type_motifs", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.typeMotif = response.data["hydra:member"];
            this.popupMotif = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    select(e) {
      this.selectEvent = e;
      this.step = 1;
      this.lieuDeb = "";
      this.LieuFin = "";
      this.monoResa == ""
      
      if (!this.$store.state.dispoOrResa) {
        this.showDispoMod = true;
      } else {
        this.showResaMod = true;
        this.headerResa = "Réservation d'un cours : " + moment(this.selectEvent.startStr).format("ddd DD/MM/YY - HH") +"h à " + moment(this.selectEvent.endStr).format("HH") + "h";
      }
    },

    setMotif() {
      this.loadMotifBtn = true;
      window.api
        .post(
          "/cancel/apointment/" +
            this.motif.leTypeMotif.id +
            "/" +
            this.selectEvent["event"]["_def"]["publicId"] +
            "/" +
            this.$store.state.user.id,
          {
            com: this.motif.com,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$emit("getEleves");
          this.motif.leTypeMotif = null;
          this.motif.com = "";
          this.popupMotif = false;
          this.loadMotifBtn = false;
          if (response.data.apointment.typeMotif != "Incident" && response.data.apointment.typeMotif != "Autre" && response.data.apointment.typeMotif != "Eleve Absent") {

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });

            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Incident") {
            let find = null;

            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });

            find.title = find.title + " - " + response.data.apointment.motif;
            find.motif = response.data.apointment.motif;
            find.color = "yellow";
            (find.textColor = "black"), filter.push(find);

            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Autre") {
            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });
            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Eleve Absent") {
            let hDiff = this.diff_hours(
              new Date(response.data.apointment.start.date),
              new Date(response.data.apointment.cancelDate.date)
            );
            let find = null;

            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });
            if (hDiff < 48) {
              find.title = find.title + " - " + response.data.apointment.motif;
              find.motif = response.data.apointment.motif;
              find.color = "red";
              filter.push(find);
              this.calendarOptions.events = filter;
            } else {
              this.calendarOptions.events = filter;
            }
          }

          window.api
            .post(
              "/sendMail",
              {
                type: "rdv annule",
                id: response.data.apointment.id,
                email: response.data.apointment.eleve.email,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    diff_hours(dt2, dt1) {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(Math.round(diff));
    },

  },
};
</script>

<style scoped>

select{
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}

input[type=text]#motifText{
  margin-top: 2em;
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

div.inputMono {
  margin-top: 2em;
  font-size: 20px;
}

button.stepBtn{
  float: right;
}

div.inputMono input {
  margin-top: 2em;
}

div.stepper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  font-size: 25px;
}

div.stepper div.firstStep{
  border : 1px solid black;
  border-radius: 3%;
  padding: 0.3%;
}

div.stepper div.link{
  padding: 0.3%;
  
}

div.stepper div.secondStep{
  border : 1px solid black;
  border-radius: 3%;
  padding: 0.3%;
}

div.stepper div.thirdStep{
  border : 1px solid black;
  border-radius: 3%;
  padding: 0.3%;
}

div.stepper div.active{
  background-color: #0d6efd;
  border : 1px solid #0d6efd;
  color: white;
}

div.stepper div.inactive{
  opacity: 0.3;
}
</style>
