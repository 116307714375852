<template>
    <div class="headerPlanning">
        <button @click="showMono(true)" class="btn btn-primary" v-if="this.$store.state.showMonoElevePage == false">Moniteurs</button>
        <button @click="showMono(false)" class="btn btn-danger" v-if="this.$store.state.showMonoElevePage == true">Planning</button>

        <!-- Rectangular switch -->
        <label class="switch">
            <input type="checkbox" id="dispoResa" v-model="this.$store.state.dispoResaBtn" @click="setDispoOrResa()">
            <span class="slider">
                <p class="resa" v-if="this.$store.state.dispoResaBtn == true">RESA</p>
                <p class="dispo" v-if="this.$store.state.dispoResaBtn == false">DISPO</p>
            </span>
        </label>

        <div class="acc">
            <p class="headp">Droit= {{eleve.droit}} h /semaine =></p>
            <button @click="showAccDiag()" class="btn btn-primary" v-if="acc == false">Passer en accéléré</button>
            <button @click="showAccDiag()" class="btn btn-danger" v-if="acc == true" disabled>Passer en accéléré</button>
        </div>

        <div class="solde">
            <button class="btn btn-primary" @click="showSolde">Solde = {{eleve.solde}} € (~{{eleve.solde / 50}}h)</button>
            <p class="headp"> =></p>
            <button @click="showCred()" class="btn btn-primary">CREDITER</button>
        </div>

        <Dialog v-model:visible="showAcc" modal header="Permis Accéléré" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
          Êtes-vous sur de vouloir passer en permis accéléré ?
          <br>
          <br>
          <br>
          <button @click="setAcc" class="btn btn-primary">VALIDER</button>
          <button @click="cancelAcc" class="btn btn-danger">RETOUR</button>
        </Dialog>

        <Dialog v-model:visible="showCredit" modal header="Créditer votre solde" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            Souhaitez-vous créditer de l'argent sur votre solde ?
          <br>
          <br>
          <label for="fname">Montant : </label>
          <input type="text" id="montant" name="montant" v-model="credit">
          <br>
          <br>

          <button @click="setCredit()" class="btn btn-primary" v-if="credit != ''">VALIDER</button>
          <button @click="cancelCred" class="btn btn-danger">RETOUR</button>
        </Dialog>


        <Dialog v-model:visible="soldeBtn" modal :header="headerSolde" :style="{ width: '50%' }">

          <template #header>
            <div class="headSolde">
              <h3>{{ this.eleve.fullname }}</h3>
              <button class="btn btn-primary btnSwitchSolde" @click="changeSoldBtn" v-if="soldeSoldebtn">Heures ></button>
              <button class="btn btn-primary btnSwitchSolde" @click="changeSoldBtn" v-if="soldeRdvBtn">&lt; Solde</button>
            </div>
          </template>

          <div class="soldePage" v-if="soldeSoldebtn">
            <DataTable :value="eleve.paiements" tableStyle="min-width: 50rem">
              <Column field="date" header="Date"></Column>
              <Column field="prestation" header="Préstation"></Column>
              <Column field="montant" header="Montant"></Column>
              <Column field="mode" header="Mode"></Column>
            </DataTable>
            
          </div>
          <div class="rdvPage" v-if="soldeRdvBtn">
            <DataTable :value="eleve.apointments" tableStyle="min-width: 50rem">
              <Column field="ladate" header="Date"></Column>
              <Column field="heure" header="Heure"></Column>
              <Column field="moniteur.fullname" header="Mono"></Column>
              <Column field="lieuDeb.nom" header="Début"></Column>
              <Column field="lieuFin.nom" header="Fin"></Column>
            </DataTable>
            
          </div>
        </Dialog>

    </div>
  </template>
  
  <script>

  export default {
  
      props: [
        "acc",
        "eleve",
        "setDisplayResaDispo"
      ],
  
    components: {

    },
  
    watch: {},
  
    data() {
      return {
        typeSelectChoice : false,
        showAcc : false,
        showCredit : false,
        credit : "",
        soldeRdvBtn : false,
        soldeSoldebtn : true,
        soldeBtn: false,
      };
    },
  
    mounted() {

    },
  
    methods: {

      showSolde(){
        if(this.soldeBtn){
          this.soldeBtn = false;
        }else{
          this.soldeBtn = true;
        }
      },

      changeSoldBtn(){
        if(this.soldeRdvBtn){
          this.soldeSoldebtn = true;
          this.soldeRdvBtn = false;
        }else{
          this.soldeSoldebtn = false;
          this.soldeRdvBtn = true;
        }
      },

      setDispoOrResa(){
        if(document.getElementById("dispoResa").checked){
          window.api
            .get("/agences/" + this.$store.state.user.agence.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              if(response.data.autoPlanning == false){
                //alert("Vous ne pouvez actuellement pas réserver de cours, votre agence a désactivé la réservation côté élève, veuillez la contacter pour réserver un cours");
                alert("Vous ne pouvez actuellement pas réserver de cours, la fonctionnalité arrivera prochainement, veuillez contacter votre agence pour réserver un cours");
                this.$store.commit("setDispoOrResa", false);
                this.$emit("setDisplayResaDispo", true);
              }else{
                this.$store.commit("setDispoOrResa", true);
                this.$emit("setDisplayResaDispo", true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }else{
          this.$store.commit("setDispoOrResa", false);
          this.$emit("setDisplayResaDispo", false);
        }
      },

      setCredit(){
        let mode = "cb";
        this.$store.dispatch("startSaveBouton", mode);
        let fonctionnalite = "solde";
        let router = this.$router;
        let montantParam = this.credit;
        this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router, montantParam });
      },

      showCred(){
        this.showCredit = true;
      },

      cancelCred(){
        this.showCredit = false;
      },

      cancelAcc(){
        this.showAcc = false;
      },

      setAcc(){
        window.api
          .post(
            "/permis/aac",
            {
              eleve: this.eleve.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            if(response.data.message != "Solde insufisant"){
              this.showAcc = false;
              this.$emit("getEleveInfo");
              window.location.reload();
            }else{
              alert("Solde de l'élève insufisant");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      showAccDiag(){
        this.showAcc = true;
      },

      showMono(e){
        if(e){
          this.$store.commit("setShowMonoElevePage", true);
        }else{
          this.$store.commit("setShowMonoElevePage", false);
        }
      }

    }
  };
  </script>
  
  <style scoped>

  p.headp{
    margin-top: 1em;
  }

  div.headerPlanning{
    display: flex;
    justify-content: space-around;
  }

  div.acc{
    display: flex;
    justify-content: space-around;
  }

  div.solde{
    display: flex;
    justify-content: space-around;
  }

  p.dispo{
    font-size: 9px;
    padding-top: 1em;
    text-align: right;
  }

  p.resa{
    font-size: 9px;
    padding-top: 1em;
    text-align: left;
  }

  div.headerPlanning{
    width: 100%;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: white;
  }
  
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ffffff;
        border-bottom: 1px solid black;
    }

    li {
        float: left;
    }

    li a {
        display: block;
        color: black;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
    }

    li a:hover:not(.active) {
        background-color: rgb(240, 240, 240);
    }

    .active {
        background-color: #f8c727;
    }

    button.custom {
        background: #2e9ea3 0% 0% no-repeat padding-box;
        border-radius: 14px;
        color: white;
        opacity: 1;
        width: 6%;
        height: 40px;
        margin: 10px;
    }

     /* The switch - the box around the slider */
.switch {
  margin-top: 1em;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


  
  </style>
  